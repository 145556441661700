import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';

import {
  listItemSectionBuilder,
  Dropdown,
  FormField,
} from '@wix/design-system';
import { marketplaceBriefGeneralAction } from '@wix/bi-logger-marketplace/v2';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { useBi } from '@components/Hooks/useBi';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';

export type CompactBriefServicesProps = {
  onChangeService: (option: any) => void;
  selectedService: string;
  showServiceError: boolean;
  isMobile?: boolean;
};

const CompactBriefServices: FC<CompactBriefServicesProps> = ({
  onChangeService,
  selectedService,
  showServiceError,
  isMobile = false,
}) => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { offeredServices, origin, entry } = useProfileContext();
  const [servicesArray, setServicesArray] = useState<[]>();
  useEffect(() => {
    const _servicesArray: any = [];
    _.map(offeredServices, (servicesForCategory, index) => {
      _servicesArray.push(
        listItemSectionBuilder({
          title: t(`explore.services.${servicesForCategory.displayKey}`),
          id: servicesForCategory.id,
        }),
      );
      _.map(servicesForCategory.services, (innerCat) => {
        if (innerCat) {
          _servicesArray.push({
            id: innerCat.id,
            value: t(`explore.services.${innerCat.displayKey}`),
          });
        }
      });
      if (_.toNumber(index) < _.size(offeredServices) - 1) {
        _servicesArray.push(
          listItemSectionBuilder({
            id: 3,
            type: 'divider',
          }),
        );
      }
    });
    setServicesArray(_servicesArray);
  }, [offeredServices, t]);

  return (
    <FormField label={t('brief-modal-services-title')} required>
      <Dropdown
        inContainer
        autoFocus={false}
        autoSelect={false}
        maxHeightPixels="350px"
        placeholder={t('brief-modal-services-placeholder')}
        options={servicesArray}
        selectedId={selectedService}
        onSelect={(option: any) => onChangeService(option.id)}
        status={showServiceError ? 'error' : undefined}
        statusMessage={t('brief-modal-services-error-message')}
        dataHook="compact-brief-services"
        onInputClicked={() => {
          logger.report(
            marketplaceBriefGeneralAction({
              briefType: 'compact',
              action_type: 'services dropdown',
              sessionId,
              origin,
              entry,
              data: '',
              step_name: 'main',
            }),
          );
        }}
        size={isMobile ? 'large' : 'medium'}
      />
    </FormField>
  );
};

export default CompactBriefServices;
