import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import {
  useHttpClient,
  useErrorMonitor,
  useTranslation,
} from '@wix/fe-essentials-standalone';
import {
  useProfileContext,
  ACTIONS,
} from '@components/Contexts/ProfileContext';
import { ReviewData } from '@common/types';
import Reviews from '@components/Reviews';
import {
  getReviewDataFromProjects,
  getServicesFromCategories,
} from './ProjectsReviewsUtils';
import s from './ProjectsReviews.module.scss';

type ProjectsReviewsProps = {
  isMobileView: boolean;
};

export const providerTranslations = {
  viewReviewSubjectCTA: 'profile-page-review-project-link',
  replyAuthorTitle: 'profile-page-review-reply-title',
  noReviews: 'profile-page-reviews-section-no-reviews-text',
};

const ProjectsReviews: FC<ProjectsReviewsProps> = ({ isMobileView }) => {
  const { dispatch, partnerProfile } = useProfileContext();
  const [reviews, setReviews] = useState<ReviewData[]>(
    getReviewDataFromProjects(partnerProfile.projects),
  );
  useEffect(() => {
    setReviews(getReviewDataFromProjects(partnerProfile.projects));
  }, [partnerProfile]);

  const errorMonitor = useErrorMonitor();
  const httpClient = useHttpClient();
  const { services, isMyProfile } = useProfileContext();
  const fetchNextPartnerProjectsPage = useCallback(async () => {
    try {
      const { data } = await httpClient.post<any>('/api/partnerProjects', {
        partnerProfileId: partnerProfile.id,
        cursor: partnerProfile.projectsCursor,
      });
      dispatch({
        type: ACTIONS.SET_PROJECTS,
        ...data,
      });
    } catch (e) {
      errorMonitor.captureMessage(`Error @fetchNextPartnerProjectsPage`, {
        contexts: {
          data: {
            partnerProfileId: partnerProfile.id,
            url: window.location.href,
          },
          error: { e },
        },
      });
    }
  }, [dispatch, errorMonitor, httpClient, partnerProfile]);

  const writeReplyToReview = useCallback(
    async (id: string, replyBody: string, revision: Long.Long | undefined) => {
      {
        const writeReplyReviewResponse = await httpClient.post<any>(
          '/api/writeReplyReview',
          {
            reply: replyBody,
            id,
            revision,
          },
        );
        const updatedProject = writeReplyReviewResponse.data.project;
        dispatch({
          type: ACTIONS.UPDATE_PROJECT,
          project: updatedProject,
        });
      }
    },
    [dispatch, httpClient],
  );

  const serviceList = useMemo(
    () => getServicesFromCategories(services),
    [services],
  );

  const borderContainerClass = useMemo(() => {
    return isMobileView ? undefined : s.reviewsWithBorderContainer;
  }, [isMobileView]);

  return (
    <Reviews
      borderContainerClass={borderContainerClass}
      isMobileView={isMobileView}
      i18nKeys={providerTranslations}
      services={serviceList}
      isMyProfile={isMyProfile}
      averageReviewRating={partnerProfile.providerRating.averageReviewRating}
      reviews={reviews}
      cursor={partnerProfile.projectsCursor}
      reviewsCount={partnerProfile.providerRating.reviewsCount}
      fetchNextReviews={fetchNextPartnerProjectsPage}
      replyProps={{
        writeReplyToReview,
      }}
      marginBottom={isMobileView ? 'SP4' : 'SP6'}
    />
  );
};

export default ProjectsReviews;
