import React, { FC, useState } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Events } from 'react-scroll';
import { Text, Divider } from '@wix/design-system';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import { useBi } from '@components/Hooks/useBi';
import LinkWithHref from '../LinkWithHref';
import s from './NavBar.module.scss';

export const NAVBAR_HEIGHT_OFFSET = -106;

type Props = {
  isMobileView?: boolean;
  navigationRefs: any;
};

export const Sections = {
  EMPTY: '',
  ABOUT: 'about',
  FEATURED_PROJECTS: 'featured-projects',
  SERVICES_AND_RATES: 'services-and-rates',
  REVIEWS: 'reviews',
};

const NavBar: FC<Props> = ({ isMobileView, navigationRefs }) => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { entry, origin, isRenderedInModal } = useProfileContext();
  const [navItemWasClicked, setNavItemWasClicked] = useState(Sections.ABOUT);

  const registerScrollEnds = (section: string) => {
    Events.scrollEvent.register('end', function (_to: any, _element: any) {
      setNavItemWasClicked(section);
      Events.scrollEvent.remove('end');
    });
  };

  const biGenericClick = (data: string) => {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType: 'top tab',
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data,
      }),
    );
  };

  const handleSetActive = () => {
    setNavItemWasClicked(Sections.EMPTY);
  };

  return (
    <div
      className={
        isMobileView
          ? s.scrollingWrapper
          : isRenderedInModal
          ? s.navnoHeader
          : s.nav
      }
      data-hook="profile-navbar"
    >
      <nav
        aria-label={t('profile-page-navigation-tabs-aria-label')}
        style={isMobileView ? { paddingLeft: '24px' } : { paddingLeft: '48px' }}
      >
        <ul>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#about"
                className={navItemWasClicked === Sections.ABOUT ? s.active : ''}
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                onSetActive={handleSetActive}
                smooth
                spy
                to={Sections.ABOUT}
                data-hook="navbar-about-tab"
                onClick={() => {
                  registerScrollEnds(Sections.ABOUT);
                  biGenericClick('about tab');
                  navigationRefs.current?.[Sections.ABOUT].focus({
                    preventScroll: true,
                  });
                }}
              >
                <span>{t('profile-page-nav-bar-about')}</span>
              </LinkWithHref>
            </Text>
          </li>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#featured-projects"
                className={
                  navItemWasClicked === Sections.FEATURED_PROJECTS
                    ? s.active
                    : ''
                }
                onSetActive={handleSetActive}
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                smooth
                spy
                to={Sections.FEATURED_PROJECTS}
                hashSpy
                onClick={() => {
                  registerScrollEnds(Sections.FEATURED_PROJECTS);
                  biGenericClick('featured projects tab');
                  navigationRefs.current?.[Sections.FEATURED_PROJECTS].focus({
                    preventScroll: true,
                  });
                }}
              >
                {t('profile-page-nav-bar-featured-projects')}
              </LinkWithHref>
            </Text>
          </li>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#starting-rates"
                className={
                  navItemWasClicked === Sections.SERVICES_AND_RATES
                    ? s.active
                    : ''
                }
                onSetActive={handleSetActive}
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                smooth
                spy
                to={Sections.SERVICES_AND_RATES}
                hashSpy
                onClick={() => {
                  registerScrollEnds(Sections.SERVICES_AND_RATES);
                  navigationRefs.current?.[Sections.SERVICES_AND_RATES].focus({
                    preventScroll: true,
                  });
                  biGenericClick('services and rates tab');
                }}
              >
                {t('profile-page-nav-bar-services-and-rates')}
              </LinkWithHref>
            </Text>
          </li>
          <li>
            <Text weight="thin" size="small">
              <LinkWithHref
                href="#reviews"
                className={
                  navItemWasClicked === Sections.REVIEWS ? s.active : ''
                }
                offset={NAVBAR_HEIGHT_OFFSET}
                activeClass={navItemWasClicked ? '' : s.active}
                smooth
                onSetActive={handleSetActive}
                spy
                to={Sections.REVIEWS}
                hashSpy
                onClick={() => {
                  registerScrollEnds(Sections.REVIEWS);
                  navigationRefs.current?.[Sections.REVIEWS].focus({
                    preventScroll: true,
                  });
                  biGenericClick('reviews tab');
                }}
              >
                {t('profile-page-nav-bar-reviews')}
              </LinkWithHref>
            </Text>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </li>
        </ul>
      </nav>
      {!isMobileView && <Divider />}
    </div>
  );
};

export default NavBar;
