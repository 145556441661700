import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Text } from '@wix/design-system';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { Link, Instagram, Facebook, LinkedIn } from '@wix/wix-ui-icons-common';
import { useBi } from '@components/Hooks/useBi';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import s from './ProviderSocialLinksMobile.module.scss';

const ProviderSocialLinksMobile: FC = () => {
  const { t } = useTranslation();
  const logger = useBi();
  const {
    partnerProfile: { professionalInformation },
    origin,
    entry,
  } = useProfileContext();
  const { sessionId } = useBiSessionContext();
  const biGenericClick = (clickType: string, data: string) => {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType,
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data,
      }),
    );
  };

  return (
    <>
      <Box
        className={s.root}
        direction="vertical"
        marginBottom="26px"
        width="100%"
        dataHook="provider-social-links-mobile"
      >
        <Text weight="bold">{t('profile-page-linked-accounts')}</Text>
        {professionalInformation?.businessWebsite && (
          <a
            target="_blank"
            rel="noreferrer"
            href={professionalInformation.businessWebsite}
            onClick={() => biGenericClick('partner website', '')}
            data-hook="partner-business-website-mobile"
          >
            <Box verticalAlign="middle" marginTop="18px">
              <Link />
              <Box width="8px"></Box>
              <Text secondary size="small">
                {t('provider-social-links-website')}
              </Text>
            </Box>
          </a>
        )}
        {professionalInformation?.socials?.instagram && (
          <a
            target="_blank"
            rel="noreferrer"
            href={professionalInformation.socials.instagram}
            onClick={() => biGenericClick('partners social media', 'Instagram')}
            data-hook="social-link-instagram-mobile"
          >
            <Box verticalAlign="middle" marginTop="10px">
              <Instagram />
              <Box width="8px"></Box>
              <Text secondary size="small">
                {t('provider-social-links-instagram')}
              </Text>
            </Box>
          </a>
        )}
        {professionalInformation?.socials?.facebook && (
          <a
            target="_blank"
            rel="noreferrer"
            href={professionalInformation.socials.facebook}
            onClick={() => biGenericClick('partners social media', 'Facebook')}
            data-hook="social-link-facebook-mobile"
          >
            <Box verticalAlign="middle" marginTop="10px">
              <Facebook />
              <Box width="8px"></Box>
              <Text secondary size="small">
                {t('provider-social-links-facebook')}
              </Text>
            </Box>
          </a>
        )}
        {professionalInformation?.socials?.linkedIn && (
          <a
            target="_blank"
            rel="noreferrer"
            href={professionalInformation.socials.linkedIn}
            onClick={() => biGenericClick('partners social media', 'LinkedIn')}
            data-hook="social-link-linkedin-mobile"
          >
            <Box verticalAlign="middle" marginTop="10px">
              <LinkedIn />
              <Box width="8px"></Box>
              <Text secondary size="small">
                {t('provider-social-links-linkedIn')}
              </Text>
            </Box>
          </a>
        )}
      </Box>
    </>
  );
};

export default ProviderSocialLinksMobile;
