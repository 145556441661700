import { useState, useRef, useEffect, useCallback } from 'react';
import _ from 'lodash';

export const useRect = () => {
  const ref = useRef();
  const [bbox, setBbox] = useState({});

  const set = _.debounce(() => {
    setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});
  }, 60);

  const debouceSet = useCallback((value) => set(value), [set]);

  useEffect(() => {
    set();
    window.addEventListener('scroll', debouceSet);
    return () => window.removeEventListener('scroll', debouceSet);
  }, []);

  return [bbox, ref];
};
