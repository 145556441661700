import React, { FC } from 'react';
import { Box, Divider } from '@wix/design-system';

type Props = {
  children: any;
  paddingTop?: string;
  paddingBottom?: string;
  isMobileView?: boolean;
};

const DividerWrapper: FC<Props> = ({
  children,
  paddingTop = '42px',
  paddingBottom = '42px',
  isMobileView = false,
}) => {
  return (
    <Box direction="vertical" paddingBottom={paddingBottom}>
      <Box
        paddingBottom={paddingTop}
        position="relative"
        left={isMobileView ? -23 : 0}
        width={isMobileView ? '100vw' : 'auto'}
      >
        <Divider />
      </Box>
      {children}
    </Box>
  );
};

export default DividerWrapper;
