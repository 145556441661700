import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, TextButton, IconButton } from '@wix/design-system';
import { LinkSmall } from '@wix/wix-ui-icons-common';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBi } from '@components/Hooks/useBi';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';

const FacebookSmall: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill="#000"
        d="M9 2a7 7 0 011.094 13.915v-4.892h1.63L12.036 9h-1.941V7.687c0-.554.27-1.093 1.14-1.093h.883V4.87s-.8-.137-1.567-.137c-1.599 0-2.644.97-2.644 2.724V9H6.13v2.023h1.777v4.892A7.002 7.002 0 019 2z"
      ></path>
    </svg>
  );
};

const InstagramSmall: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M11.997 4.005c2.172 0 2.445.01 3.298.048.851.039 1.432.174 1.941.372a3.92 3.92 0 011.417.922c.444.445.718.891.922 1.417.198.509.333 1.09.372 1.941.039.853.048 1.126.048 3.298s-.01 2.444-.048 3.297c-.039.851-.174 1.432-.372 1.941a3.92 3.92 0 01-.922 1.417 3.92 3.92 0 01-1.417.922c-.509.198-1.09.333-1.941.372-.853.039-1.126.048-3.298.048s-2.444-.01-3.297-.048c-.851-.039-1.432-.174-1.941-.372a3.92 3.92 0 01-1.417-.922 3.92 3.92 0 01-.922-1.417c-.198-.509-.333-1.09-.372-1.941C4.01 14.447 4 14.175 4 12.003s.01-2.445.048-3.298c.039-.851.174-1.432.372-1.941a3.92 3.92 0 01.922-1.417 3.92 3.92 0 011.417-.922c.509-.198 1.09-.333 1.941-.372.853-.039 1.125-.048 3.297-.048zm0 1.441c-2.135 0-2.388.008-3.231.047-.78.035-1.203.166-1.485.275-.374.145-.64.318-.92.598-.28.28-.453.547-.598.92-.11.282-.24.705-.275 1.485-.039.843-.047 1.096-.047 3.232 0 2.135.008 2.388.047 3.231.035.78.165 1.203.275 1.485.145.374.318.64.598.92.28.28.546.453.92.598.282.11.705.24 1.485.275.843.039 1.096.047 3.231.047 2.136 0 2.389-.008 3.232-.047.78-.035 1.203-.165 1.485-.275.373-.145.64-.318.92-.598.28-.28.453-.546.598-.92.11-.282.24-.705.275-1.485.039-.843.047-1.096.047-3.231 0-2.136-.008-2.389-.047-3.232-.035-.78-.166-1.203-.275-1.485a2.478 2.478 0 00-.598-.92 2.478 2.478 0 00-.92-.598c-.282-.11-.705-.24-1.485-.275-.843-.039-1.096-.047-3.232-.047zm0 9.222a2.666 2.666 0 100-5.331 2.666 2.666 0 000 5.331zm0-6.772a4.107 4.107 0 110 8.213 4.107 4.107 0 010-8.213zM16 9a1 1 0 110-2 1 1 0 010 2z"
      ></path>
    </svg>
  );
};

const LinkedInSmall: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M6.578 5a1.5 1.5 0 10.001 3.001A1.5 1.5 0 006.578 5zm9.183 4.223c-1.638 0-2.761.785-2.761 1.785v-2h-3c.039 1 0 10 0 10h3v-5.64c0-.279.033-.559.114-.759.219-.558.733-1.138 1.57-1.138 1.1 0 1.316.859 1.316 2.117v5.42h3v-5.779c0-2.875-1.241-4.006-3.239-4.006zM5 19.008h3v-10H5v10z"
      ></path>
    </svg>
  );
};

const ProviderSocialLinks: FC = () => {
  const logger = useBi();
  const { t } = useTranslation();
  const { sessionId } = useBiSessionContext();
  const {
    partnerProfile: { professionalInformation },
    entry,
    origin,
  } = useProfileContext();
  const biGenericClick = (clickType: string, data: string) => {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType,
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data,
      }),
    );
  };
  return (
    <Box
      padding="24px"
      align="space-between"
      verticalAlign="middle"
      dataHook="provider-social-links"
    >
      <Box>
        <LinkSmall />
        <Box width="5px"></Box>
        <TextButton
          skin="dark"
          size="tiny"
          underline="always"
          weight="thin"
          as="a"
          target="_blank"
          dataHook="partner-business-website"
          href={professionalInformation?.businessWebsite}
          onClick={() => biGenericClick('partner website', '')}
        >
          {t('provider-social-links-website')}
        </TextButton>
      </Box>
      <Box gap="6px">
        {professionalInformation?.socials?.instagram && (
          <IconButton
            skin="light"
            size="tiny"
            as="a"
            target="_blank"
            dataHook="social-link-instagram"
            href={professionalInformation.socials.instagram}
            onClick={() => biGenericClick('partners social media', 'Instagram')}
          >
            <InstagramSmall />
          </IconButton>
        )}
        {professionalInformation?.socials?.facebook && (
          <IconButton
            skin="light"
            size="tiny"
            as="a"
            target="_blank"
            dataHook="social-link-facebook"
            href={professionalInformation.socials.facebook}
            onClick={() => biGenericClick('partners social media', 'Facebook')}
          >
            <FacebookSmall />
          </IconButton>
        )}
        {professionalInformation?.socials?.linkedIn && (
          <IconButton
            skin="light"
            size="tiny"
            as="a"
            target="_blank"
            dataHook="social-link-linkedin"
            href={professionalInformation.socials.linkedIn}
            onClick={() => biGenericClick('partners social media', 'LinkedIn')}
          >
            <LinkedInSmall />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ProviderSocialLinks;
