import React, { FC } from 'react';

interface Props {
  className?: string;
}

const WixLogo: FC<Props> = () => {
  return (
    <svg
      width="27"
      height="10"
      viewBox="0 0 27 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1384_266326)">
        <path
          d="M26.154 0H24.7272C24.3314 0 23.9615 0.195988 23.7392 0.523748L21.8312 3.33848C21.7803 3.41312 21.6702 3.41312 21.6193 3.33848L19.7113 0.523748C19.4895 0.195988 19.1191 0 18.7233 0H17.2966L20.6956 5.01397L17.3154 10H18.7421C19.1379 10 19.5078 9.80401 19.7301 9.47625L21.6193 6.68946C21.6702 6.61482 21.7803 6.61482 21.8312 6.68946L23.7205 9.47625C23.9423 9.80401 24.3127 10 24.7085 10H26.1352L22.755 5.01397L26.154 0Z"
          fill="black"
        />
        <path
          d="M14.3608 1.02289V10H15.0427C15.6078 10 16.0657 9.54214 16.0657 8.97711V0H15.3838C14.8187 0 14.3608 0.457862 14.3608 1.02289Z"
          fill="black"
        />
        <path
          d="M13.1107 0H12.5039C11.8358 0 11.2569 0.462032 11.1089 1.11338L9.75138 7.08102L8.5632 1.51036C8.34341 0.480797 7.28702 -0.213502 6.20602 0.0850673C5.51747 0.275218 5.00699 0.856928 4.85811 1.5554L3.68202 7.07352L2.32702 1.1138C2.17854 0.462449 1.59968 0 0.931559 0H0.325165L2.59935 9.99958H3.46098C4.24295 9.99958 4.91858 9.4529 5.08165 8.68813L6.53549 1.86564C6.55342 1.78099 6.62933 1.71969 6.71566 1.71969C6.80199 1.71969 6.87789 1.78099 6.89582 1.86564L8.35092 8.68854C8.51399 9.45332 9.18961 9.99958 9.97158 9.99958H10.8361L13.1107 0Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1384_266326">
          <rect
            width="25.8289"
            height="10"
            fill="white"
            transform="translate(0.325165)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WixLogo;
