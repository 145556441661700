import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, TextButton } from '@wix/design-system';
import { ArrowLeftSmall } from '@wix/wix-ui-icons-common';

const BackToExplorer: FC = () => {
  const { t } = useTranslation();
  const [showBack, setShowBack] = useState(false);
  useEffect(() => {
    setShowBack(document.referrer.indexOf('hire') > -1);
  }, []);
  if (showBack) {
    return (
      <Box paddingBottom="21px">
        <TextButton
          onClick={() => {
            history.back();
          }}
          size="small"
          skin="dark"
          prefixIcon={<ArrowLeftSmall />}
        >
          {t('back-to-explorer-back')}
        </TextButton>
      </Box>
    );
  }
  return <Box height="4px"></Box>;
};

export default BackToExplorer;
