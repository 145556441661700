import React from 'react';

const StudioBadgeSvg = () => (
  <svg
    width="66"
    height="19"
    viewBox="0 0 66 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 2C0 0.895431 0.895431 0 2 0H66V19H0V2Z" fill="#22232B" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.197 6.10156L15.6054 6.10156L14.0745 13.1665H13.4926C12.9663 13.1665 12.5115 12.7805 12.4018 12.2402L11.4224 7.41968C11.4104 7.35988 11.3593 7.31657 11.3012 7.31657C11.2431 7.31657 11.192 7.35988 11.1799 7.41968L10.2014 12.2399C10.0916 12.7803 9.63689 13.1665 9.11058 13.1665H8.53066L7 6.10156H7.40814C7.85782 6.10156 8.24743 6.42829 8.34736 6.88849L9.25935 11.0992L10.0509 7.20049C10.1511 6.707 10.4947 6.29601 10.9581 6.16166C11.6857 5.95072 12.3967 6.44126 12.5447 7.16867L13.3444 11.1045L14.2581 6.88819C14.3577 6.428 14.7473 6.10156 15.197 6.10156ZM16.2188 6.82423V13.1665H16.7104C17.1179 13.1665 17.4481 12.843 17.4481 12.4438V6.10156H16.9564C16.5489 6.10156 16.2188 6.42504 16.2188 6.82423ZM24.2112 6.10156H23.2211C22.9464 6.10156 22.6897 6.24003 22.5354 6.47159L21.2113 8.46017C21.176 8.51291 21.0996 8.51291 21.0643 8.46017L19.7402 6.47159C19.5862 6.24003 19.3292 6.10156 19.0546 6.10156H18.0645L20.4232 9.6439L18.0775 13.1665H19.0676C19.3422 13.1665 19.599 13.028 19.7532 12.7965L21.0643 10.8276C21.0996 10.7749 21.176 10.7749 21.2113 10.8276L22.5224 12.7965C22.6764 13.028 22.9334 13.1665 23.208 13.1665H24.1981L21.8524 9.6439L24.2112 6.10156Z"
      fill="white"
    />
    <path
      d="M25.0315 11.9743L25.5852 11.4497C25.8896 11.7994 26.2393 12.0747 26.6343 12.2754C27.0359 12.4762 27.4892 12.5766 27.9943 12.5766C28.37 12.5766 28.7035 12.5183 28.9949 12.4017C29.2863 12.2851 29.5162 12.1232 29.6846 11.916C29.853 11.7023 29.9372 11.453 29.9372 11.168C29.9372 10.8053 29.785 10.5236 29.4806 10.3229C29.1762 10.1156 28.6581 9.95695 27.9263 9.84686C27.2917 9.74971 26.7833 9.62343 26.4012 9.468C26.0256 9.3061 25.7536 9.10533 25.5852 8.86571C25.4168 8.6261 25.3326 8.3379 25.3326 8.00114C25.3326 7.60609 25.4427 7.25962 25.6629 6.96171C25.8831 6.65733 26.1875 6.42095 26.5761 6.25257C26.9646 6.08419 27.4115 6 27.9166 6C28.493 6 29.0014 6.11333 29.4418 6.34C29.8886 6.56667 30.2837 6.88076 30.6269 7.28229L30.0829 7.79714C29.8044 7.4539 29.4968 7.1819 29.1601 6.98114C28.8298 6.78038 28.4153 6.68 27.9166 6.68C27.3856 6.68 26.9517 6.79981 26.6149 7.03943C26.2781 7.27257 26.1098 7.5899 26.1098 7.99143C26.1098 8.30229 26.2522 8.54514 26.5372 8.72C26.8221 8.89486 27.3111 9.0341 28.0041 9.13771C28.6646 9.23486 29.1924 9.37086 29.5875 9.54571C29.9825 9.7141 30.2675 9.93105 30.4423 10.1966C30.6237 10.4621 30.7143 10.7827 30.7143 11.1583C30.7143 11.5728 30.5978 11.9387 30.3646 12.256C30.138 12.5669 29.8206 12.813 29.4126 12.9943C29.0046 13.1691 28.5319 13.2566 27.9943 13.2566C27.3726 13.2566 26.8157 13.1432 26.3235 12.9166C25.8378 12.6899 25.4071 12.3758 25.0315 11.9743Z"
      fill="white"
    />
    <path
      d="M34.3738 13.1011H33.6258V6.82571H31.3138V6.15543H36.6858V6.82571H34.3738V13.1011Z"
      fill="white"
    />
    <path
      d="M43.0292 6.15543V10.3617C43.0292 11.2749 42.7831 11.9872 42.291 12.4989C41.7988 13.004 41.1188 13.2566 40.251 13.2566C39.3896 13.2566 38.7096 13.004 38.211 12.4989C37.7188 11.9872 37.4727 11.2749 37.4727 10.3617V6.15543H38.2207V10.3617C38.2207 11.0806 38.3988 11.631 38.755 12.0131C39.1111 12.3888 39.6098 12.5766 40.251 12.5766C40.8986 12.5766 41.4005 12.3888 41.7567 12.0131C42.1129 11.631 42.291 11.0806 42.291 10.3617V6.15543H43.0292Z"
      fill="white"
    />
    <path
      d="M44.289 13.1011V6.15543H46.533C47.2454 6.15543 47.8703 6.30438 48.4078 6.60229C48.9518 6.90019 49.376 7.31143 49.6804 7.836C49.9848 8.3541 50.137 8.9499 50.137 9.62343C50.137 10.297 49.9848 10.896 49.6804 11.4206C49.376 11.9451 48.9518 12.3564 48.4078 12.6543C47.8703 12.9522 47.2454 13.1011 46.533 13.1011H44.289ZM45.037 12.4211H46.533C47.1094 12.4211 47.608 12.3046 48.029 12.0714C48.4564 11.8383 48.7835 11.5112 49.0101 11.0903C49.2433 10.6693 49.3598 10.1804 49.3598 9.62343C49.3598 9.06648 49.2433 8.58076 49.0101 8.16629C48.7835 7.74533 48.4564 7.41829 48.029 7.18514C47.608 6.952 47.1094 6.83543 46.533 6.83543H45.037V12.4211Z"
      fill="white"
    />
    <path d="M51.9459 13.1011H51.1979V6.15543H51.9459V13.1011Z" fill="white" />
    <path
      d="M56.6192 13.2566C56.1011 13.2566 55.6218 13.1659 55.1814 12.9846C54.7475 12.8032 54.3654 12.5507 54.0351 12.2269C53.7113 11.8966 53.4588 11.5112 53.2774 11.0709C53.1026 10.624 53.0151 10.1415 53.0151 9.62343C53.0151 9.10533 53.1026 8.6261 53.2774 8.18571C53.4588 7.74533 53.7113 7.36324 54.0351 7.03943C54.3654 6.70914 54.7475 6.45333 55.1814 6.272C55.6218 6.09067 56.1011 6 56.6192 6C57.1372 6 57.6132 6.09067 58.0471 6.272C58.4875 6.45333 58.8696 6.70914 59.1934 7.03943C59.5237 7.36324 59.7763 7.74533 59.9511 8.18571C60.1325 8.6261 60.2231 9.10533 60.2231 9.62343C60.2231 10.1415 60.1325 10.624 59.9511 11.0709C59.7763 11.5112 59.5237 11.8966 59.1934 12.2269C58.8696 12.5507 58.4875 12.8032 58.0471 12.9846C57.6132 13.1659 57.1372 13.2566 56.6192 13.2566ZM56.6192 12.5766C57.1761 12.5766 57.6651 12.4503 58.086 12.1977C58.5134 11.9451 58.847 11.5954 59.0866 11.1486C59.3262 10.7017 59.446 10.1933 59.446 9.62343C59.446 9.05352 59.3262 8.54838 59.0866 8.108C58.847 7.66114 58.5134 7.31143 58.086 7.05886C57.6651 6.80629 57.1761 6.68 56.6192 6.68C56.0687 6.68 55.5797 6.80629 55.1523 7.05886C54.7249 7.31143 54.3913 7.66114 54.1517 8.108C53.9121 8.54838 53.7923 9.05352 53.7923 9.62343C53.7923 10.1933 53.9121 10.7017 54.1517 11.1486C54.3913 11.5954 54.7249 11.9451 55.1523 12.1977C55.5797 12.4503 56.0687 12.5766 56.6192 12.5766Z"
      fill="white"
    />
  </svg>
);

export default StudioBadgeSvg;
