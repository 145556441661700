import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Heading, TextButton } from '@wix/design-system';
import { animateScroll } from 'react-scroll';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import { useBi } from '@components/Hooks/useBi';
import Project from './Project';
import { PortfolioItemWithUrl } from './Project/Project';
import s from './FeaturedProjects.module.scss';

export const PORTFOLIO_DISPLAY_OFFSET = 6;

type Props = {
  isMobileView?: boolean;
};

const FeaturedProjects: FC<Props> = ({ isMobileView }) => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { entry, origin, partnerProfile } = useProfileContext();
  useEffect(() => {
    setImageSlideWidth(
      isMobileView ? (window.screen.width * 93.6) / 100 + 'px' : 'auto',
    );
  }, [isMobileView]);

  const [quantityToDisplay, setQuantityToDisplay] = useState<number>(
    PORTFOLIO_DISPLAY_OFFSET,
  );
  const [imageSlideWidth, setImageSlideWidth] = useState('auto');

  const maxLength = _.size(partnerProfile.portfolio?.items);
  const shouldDisplayShowMore = maxLength > PORTFOLIO_DISPLAY_OFFSET;
  const remainingLength = maxLength - quantityToDisplay;
  const haveMoreToShow = remainingLength > 0;

  const onClickShowMoreOrLess = () => {
    if (haveMoreToShow) {
      const offsetIncrease =
        remainingLength < PORTFOLIO_DISPLAY_OFFSET
          ? remainingLength
          : PORTFOLIO_DISPLAY_OFFSET;
      setQuantityToDisplay((num) => num + offsetIncrease);
      logger.report(
        copyOfExploreResultsPageGenericClick({
          clickType: 'show more results',
          sessionId,
          origin,
          entry,
          exploreVersion: 'V2',
          data: '',
        }),
      );
    } else {
      setQuantityToDisplay(PORTFOLIO_DISPLAY_OFFSET);
      const sectionId = 'featured-projects';
      const scrollTargetElement = document.getElementById(sectionId);
      if (scrollTargetElement) {
        const scrollOffset =
          scrollTargetElement.getBoundingClientRect().top - 100;
        animateScroll.scrollMore(scrollOffset, {
          duration: 0,
        });
      }
    }
  };

  const projectCount = _.size(partnerProfile.portfolio?.items);
  return (
    <Box
      direction="vertical"
      dataHook="featured-projects"
      width={imageSlideWidth}
    >
      <Heading size={isMobileView ? 'medium' : 'large'} as="h2">
        {t('profile-page-featured-projects-section-title', {
          projectCount,
        })}
      </Heading>

      {isMobileView ? (
        <>
          <div className={s.projectsMobile}>
            {_.map(partnerProfile.portfolio?.items, (portfolioItem) => (
              <Project
                {...portfolioItem}
                key={portfolioItem.metaSiteId}
                isMobileView={isMobileView}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={s.projects} margin-top="24px">
            {_.get(partnerProfile, 'portfolio.items', [])!
              .slice(0, quantityToDisplay)
              .map(function (
                portfolioItem: JSX.IntrinsicAttributes & PortfolioItemWithUrl,
              ) {
                return (
                  <Project
                    {...portfolioItem}
                    key={portfolioItem.metaSiteId}
                    isMobileView={isMobileView}
                  />
                );
              })}
          </div>
          {shouldDisplayShowMore && (
            <Box align="center" marginTop="24px">
              <TextButton
                dataHook="show-more-less-button"
                skin="dark"
                underline="always"
                onClick={onClickShowMoreOrLess}
              >
                {t(
                  haveMoreToShow
                    ? 'profile-page-featured-projects-show-more'
                    : 'profile-page-featured-projects-show-fewer',
                )}
              </TextButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default FeaturedProjects;
