import React, { FC } from 'react';
import _ from 'lodash';
import {
  Layout,
  Cell,
  SkeletonGroup,
  SkeletonRectangle,
  SkeletonLine,
  Divider,
  Box,
} from '@wix/design-system';
import { useProfileContext } from '../../Contexts/ProfileContext';

type Props = {
  isMobile: boolean;
};

const LoadingCompactBrief: FC<Props> = ({ isMobile }) => {
  const { userDetails } = useProfileContext();
  const email = _.get(userDetails, 'email');

  function renderLoadingBriefModal() {
    return (
      <SkeletonGroup skin="light">
        <Layout>
          <Cell span={12}>
            <Layout gap="12px">
              <Cell span={3}>
                <SkeletonLine />
              </Cell>
              <Cell span={12}>
                <SkeletonRectangle height="35px" />
              </Cell>
            </Layout>
          </Cell>
          <Cell span={12}>
            <Layout gap="12px">
              <Cell span={3}>
                <SkeletonLine />
              </Cell>
              <Cell span={12}>
                <SkeletonRectangle height="60px" />
              </Cell>
            </Layout>
          </Cell>
          <Cell span={12}>
            <Divider direction="horizontal" />
          </Cell>
          <Cell span={6}>
            <Layout gap="12px">
              <Cell span={6}>
                <SkeletonLine />
              </Cell>
              <Cell span={12}>
                <SkeletonRectangle height="35px" />
              </Cell>
            </Layout>
          </Cell>
          {email ? (
            <Cell span={6}>
              <Layout gap="12px">
                <Cell span={6}>
                  <SkeletonLine />
                </Cell>
                <Cell span={12}>
                  <SkeletonRectangle height="35px" />
                </Cell>
              </Layout>
            </Cell>
          ) : (
            <></>
          )}

          <Cell span={6}>
            <Layout gap="12px">
              <Cell span={6}>
                <SkeletonLine />
              </Cell>
              <Cell span={12}>
                <SkeletonRectangle height="35px" />
              </Cell>
            </Layout>
          </Cell>
          <Cell span={6}>
            <Layout gap="12px">
              <Cell span={6}>
                <SkeletonLine />
              </Cell>
              <Cell span={12}>
                <SkeletonRectangle height="35px" />
              </Cell>
            </Layout>
          </Cell>
          <Cell span={12}>
            <Divider direction="horizontal" />
          </Cell>
        </Layout>
      </SkeletonGroup>
    );
  }

  function renderLoadingBriefModalMobile() {
    return (
      <Box direction="vertical" marginTop="10px" gap="30px">
        <Cell span={12}>
          <Layout gap="12px">
            <Cell span={6}>
              <SkeletonLine />
            </Cell>
            <Cell span={12}>
              <SkeletonRectangle height="35px" />
            </Cell>
          </Layout>
        </Cell>
        <Cell span={12}>
          <Layout gap="12px">
            <Cell span={6}>
              <SkeletonLine />
            </Cell>
            <Cell span={12}>
              <SkeletonRectangle height="70px" />
            </Cell>
          </Layout>
        </Cell>
        <Cell span={12}>
          <Divider direction="horizontal" />
        </Cell>
        <Cell span={6}>
          <Layout gap="12px">
            <Cell span={6}>
              <SkeletonLine />
            </Cell>
            <Cell span={12}>
              <SkeletonRectangle height="35px" />
            </Cell>
          </Layout>
        </Cell>
        {email ? (
          <Cell span={12}>
            <Layout gap="12px">
              <Cell span={6}>
                <SkeletonLine />
              </Cell>
              <Cell span={12}>
                <SkeletonRectangle height="35px" />
              </Cell>
            </Layout>
          </Cell>
        ) : (
          <></>
        )}
        <Cell span={12}>
          <Layout gap="12px">
            <Cell span={6}>
              <SkeletonLine />
            </Cell>
            <Cell span={12}>
              <SkeletonRectangle height="35px" />
            </Cell>
          </Layout>
        </Cell>
        <Box paddingBottom="25px" width="100%" display="block">
          <Cell span={12}>
            <Layout gap="12px">
              <Cell span={6}>
                <SkeletonLine />
              </Cell>
              <Cell span={12}>
                <SkeletonRectangle height="35px" />
              </Cell>
            </Layout>
          </Cell>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isMobile ? renderLoadingBriefModalMobile() : renderLoadingBriefModal()}
    </>
  );
};

export default LoadingCompactBrief;
