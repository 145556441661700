import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { marketplaceBriefGeneralAction } from '@wix/bi-logger-marketplace/v2';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Dropdown, FormField } from '@wix/design-system';
import { useBi } from '@components/Hooks/useBi';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';

export type LanguagePickerProps = {
  setPreferredLanguage: (id: any) => void;
  preferredLanguage: string;
  isMobile: boolean;
};

const LanguagePickerField: FC<LanguagePickerProps> = ({
  preferredLanguage,
  setPreferredLanguage,
  isMobile,
}) => {
  const logger = useBi();
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState<any>([]);
  const { sessionId } = useBiSessionContext();
  const { languages, userDetails, origin, entry } = useProfileContext();
  const language = _.get(userDetails, 'language') || i18n.language;
  useEffect(() => {
    const myOptions: any = [];
    _.map(languages, (currentLanguage) => {
      myOptions.push({
        id: currentLanguage.id,
        value: currentLanguage.value,
      });
    });
    setOptions(myOptions);
    setPreferredLanguage(language);
  }, [t]);
  return (
    <FormField label={t('brief-modal-preferred-language-title')} required>
      <Dropdown
        native={isMobile}
        popoverProps={{ placement: 'top-start' }}
        options={options}
        selectedId={preferredLanguage}
        onSelect={(option: any) => {
          setPreferredLanguage(option.id);
          logger.report(
            marketplaceBriefGeneralAction({
              briefType: 'compact',
              action_type: 'choose language',
              sessionId,
              origin,
              entry,
              data: JSON.stringify({ preferredLanguage: option.value }),
              step_name: 'main',
            }),
          );
        }}
        dataHook="brief-language-picker"
        onInputClicked={() => {
          logger.report(
            marketplaceBriefGeneralAction({
              briefType: 'compact',
              action_type: 'language dropdown',
              sessionId,
              origin,
              entry,
              data: '',
              step_name: 'main',
            }),
          );
        }}
        size={isMobile ? 'large' : 'medium'}
      />
    </FormField>
  );
};

export default LanguagePickerField;
