import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Image, Text, Divider, Button } from '@wix/design-system';
import { useRect } from '../../Hooks/useRect';
import { useProfileContext } from '../../Contexts/ProfileContext';
import ProviderReviewSummary from '../ProviderReviewSummary';
import { fillProfileUrl } from '../../../utils/urlUtils';
import s from './MiniContactPanel.module.scss';

type Props = {
  onContactClicked?: () => void;
};

const MiniContactPanel: FC<Props> = ({ onContactClicked }) => {
  const { t } = useTranslation();
  const { partnerProfile, isMyProfile, enabled, isRenderedInModal } =
    useProfileContext();
  const [showStickyMobileView, setShowStickyMobileView] = useState(false);
  const [showStickyMobileViewOpacity, setShowStickyMobileViewOpacity] =
    useState(false);
  const [bbox, ref] = useRect() as any;
  useEffect(() => {
    bbox.y < 50
      ? setShowStickyMobileView(true)
      : setShowStickyMobileView(false);
  }, [bbox]);

  useEffect(() => {
    if (showStickyMobileView) {
      setTimeout(() => {
        setShowStickyMobileViewOpacity(true);
      }, 100);
    } else {
      setShowStickyMobileViewOpacity(false);
    }
  }, [showStickyMobileView]);

  function renderStickyMobileView() {
    return (
      <div
        className={s.stickyNav}
        style={{
          opacity: showStickyMobileViewOpacity ? 1 : 0,
          display: showStickyMobileView ? 'block' : 'none',
        }}
      >
        <Box
          direction="vertical"
          height="77px"
          dataHook="profile-mini-contact-panel"
        >
          <Box
            backgroundColor="#fff"
            direction="horizontal"
            padding="17.5px 25px 17.5px 24px"
            align="space-between"
          >
            <Box>
              <div
                style={{
                  border: '1px solid #FFFFFF',
                  minWidth: '40px',
                  height: '40px',
                  backgroundColor: '#FFFFFF',
                  boxShadow:
                    '0px 0px 6px rgba(0, 6, 36, 0.12), 0px 2px 4px rgba(0, 6, 36, 0.12)',
                  borderRadius: '100px',
                }}
              >
                <Image
                  alt=""
                  borderRadius="100px"
                  transparent
                  width="42px"
                  height="42px"
                  src={fillProfileUrl(
                    partnerProfile?.professionalInformation?.profileImage || '',
                    180,
                    180,
                  )}
                />
              </div>
              <Box direction="vertical" margin="0 15px">
                <Box marginLeft="5px">
                  <Text
                    className={s.text}
                    size="small"
                    weight="normal"
                    maxLines={1}
                  >
                    {partnerProfile.professionalInformation?.businessName}
                  </Text>
                </Box>
                <ProviderReviewSummary isStickyMobileView={true} />
              </Box>
            </Box>
            {!isMyProfile && enabled && !isRenderedInModal && (
              <Box>
                <Button
                  dataHook="profile-contact-button"
                  onClick={onContactClicked}
                >
                  {t('marketplace-contact-button')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Divider className={s.divider} />
      </div>
    );
  }
  return <div ref={ref}>{renderStickyMobileView()}</div>;
};

export default MiniContactPanel;
