import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { Box, EmptyState, Button } from '@wix/design-system';
import { useBi } from '@components/Hooks/useBi';
import useMobileMediaQuery from '@components/Hooks/useMobileMediaQuery';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import Header from '@components/Header';
import { MARKETPLACE_URL } from '@utils/constants';
import s from './NotFound.module.scss';

const NotFound: FC = () => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { entry, origin, host, isMobileUserAgent } = useProfileContext();
  const isMobileView = useMobileMediaQuery(isMobileUserAgent);

  function headerOnClick() {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType: 'marketplace top button',
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data: '',
      }),
    );
  }

  return (
    <Box display="block" className={s.root}>
      <div className={s.stickyHeader}>
        <Header
          onClick={headerOnClick}
          sessionId={sessionId}
          pageName="not found page"
        />
      </div>
      <Box
        align="center"
        marginTop="208px"
        height={`calc(100vh - 208px - ${isMobileView ? '54px' : '72px'})`}
      >
        <EmptyState
          theme="page-no-border"
          title={t('profile-page-not-found-title')}
          subtitle={t('profile-page-not-found-subtitle')}
          dataHook="profile-page-not-found"
        >
          <Button
            skin="dark"
            dataHook="profile-page-not-found-ctr"
            as="a"
            href={host + MARKETPLACE_URL}
          >
            {t('profile-page-not-found-cta')}
          </Button>
        </EmptyState>
      </Box>
    </Box>
  );
};

export default NotFound;
