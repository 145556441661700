import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash';
import {
  FormField,
  Layout,
  Cell,
  listItemSelectBuilder,
  Input,
  AutoComplete,
} from '@wix/design-system';
import { marketplaceBriefGeneralAction } from '@wix/bi-logger-marketplace/v2';
import { useTranslation } from '@wix/fe-essentials-standalone';
import localeData from '@wix/locale-dataset-javascript';
import { useBi } from '@components/Hooks/useBi';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';

export type PhoneNumberFieldProps = {
  setPhoneNumberWithCountry: (phoneNumber: string) => void;
  setIsPhoneLegit: (isLegit: boolean) => void;
  isMobile: boolean;
  phoneNumberWithCountry: string;
  userGUIDFromURL: string | null;
};

const PhoneNumberField: FC<PhoneNumberFieldProps> = ({
  setPhoneNumberWithCountry,
  setIsPhoneLegit,
  isMobile,
  phoneNumberWithCountry,
  userGUIDFromURL,
}) => {
  const {
    t,
    i18n,
    i18n: { language },
  } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { geo, origin, entry, localeDataTranslations } = useProfileContext();

  const [countryNumber, setCountryNumber] = useState<string>('');
  const [isCountryCodeError, setIsCountryCodeError] = useState<boolean>(false);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberLoggerSent, setPhoneNumberLoggerSent] =
    useState<boolean>(false);

  const sortedOptions = useMemo(() => {
    i18n.addResourceBundle(
      language,
      'translation',
      localeDataTranslations,
      true,
      false,
    );

    const options: any = localeData.getAllCountries().map((country: any) => {
      return listItemSelectBuilder({
        id: `${country.shortKey} ${t(country.displayName)}`,
        title: t(country.displayName),
        suffix: country.dialCode,
        label: country.dialCode,
      });
    });

    if (geo) {
      const geoCountryCode = geo['2lettersCountryCode'];
      const userCountryNumber = options.find((country: { id: string }) =>
        (country.id as string).startsWith(geoCountryCode || ''),
      );
      if (userCountryNumber) {
        options.sort((x: string, y: number) =>
          x === userCountryNumber ? -1 : y === userCountryNumber ? 1 : 0,
        );
        return options;
      }
    }
    return options;
  }, [localeDataTranslations, geo]);

  const onSelectCountryCode = useCallback(
    (label?: string) => {
      setCountryNumber(label || '');
      logger.report(
        marketplaceBriefGeneralAction({
          briefType: 'compact',
          action_type: 'choose country code',
          sessionId,
          origin,
          entry,
          data: JSON.stringify({ selectedCountryCode: label }),
          step_name: 'main',
        }),
      );
    },
    [setCountryNumber, logger, sessionId, origin, entry],
  );

  const predicateCountryCode = (option: any) => {
    return (
      (option.label &&
        option.label.toLowerCase().includes(countryNumber.toLowerCase())) ||
      (option.id &&
        option.id.toLowerCase().includes(countryNumber.toLowerCase()))
    );
  };

  const handlePhoneNumberChange = (event: any) => {
    if (!phoneNumberLoggerSent) {
      logger.report(
        marketplaceBriefGeneralAction({
          briefType: 'compact',
          action_type: 'type phone number',
          sessionId,
          origin,
          entry,
          data: 'true',
          step_name: 'main',
        }),
      );
      setPhoneNumberLoggerSent(true);
    }
    const { value } = event.target;
    setPhoneNumber(value.trim());
    const phoneNumbeRegExp = new RegExp(
      `^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$`,
    ).exec(value.trim());
    if (_.size(phoneNumbeRegExp) !== 0 || value.trim() === '') {
      setIsPhoneNumberError(false);
    } else {
      setIsPhoneNumberError(true);
    }
  };

  useEffect(() => {
    if (
      sortedOptions.length > 0 &&
      sortedOptions.filter((e: { label: string }) => e.label === countryNumber)
        .length < 1 &&
      countryNumber !== ''
    ) {
      setIsCountryCodeError(true);
    } else {
      setIsCountryCodeError(false);
    }
  }, [countryNumber, sortedOptions]);

  useEffect(() => {
    if (phoneNumber) {
      countryNumber
        ? setPhoneNumberWithCountry(`${countryNumber}-${phoneNumber}`)
        : setPhoneNumberWithCountry(phoneNumber);
    }
  }, [countryNumber, phoneNumber, setPhoneNumberWithCountry]);

  useEffect(() => {
    if (isCountryCodeError) {
      setIsPhoneLegit(false);
    } else if (isPhoneNumberError) {
      setIsPhoneLegit(false);
    } else {
      setIsPhoneLegit(true);
    }
  }, [isCountryCodeError, isPhoneNumberError, setIsPhoneLegit]);

  useEffect(() => {
    if (userGUIDFromURL && phoneNumberWithCountry) {
      const CountryNumberFromPayload = phoneNumberWithCountry.split('-')[0];
      const phoneNumberFromPayload = phoneNumberWithCountry.slice(
        phoneNumberWithCountry.indexOf('-') + 1,
      );
      setCountryNumber(CountryNumberFromPayload || '');
      setPhoneNumber(phoneNumberFromPayload || '');
    }
  }, [phoneNumberWithCountry, userGUIDFromURL]);

  return (
    <FormField label={t('brief-modal-phone-number-title')}>
      <Layout gap="12px">
        <Cell span={isMobile ? 4 : 3}>
          <AutoComplete
            ariaLabel="country-code"
            placeholder="+00"
            dataHook="country-code-autocomplete"
            autocomplete="null"
            popoverProps={{
              placement: 'top-start',
            }}
            value={countryNumber}
            options={sortedOptions}
            autoFocus={false}
            autoSelect={false}
            dropdownWidth="300px"
            onSelect={({ label }: any) => onSelectCountryCode(label)}
            onChange={(e: any) => setCountryNumber(e.target.value)}
            predicate={predicateCountryCode}
            status={isCountryCodeError ? 'error' : undefined}
            statusMessage={t(`brief-modal-phone-country-error-message`)}
            onInputClicked={(_e: any) => {
              logger.report(
                marketplaceBriefGeneralAction({
                  briefType: 'compact',
                  action_type: 'phone dropdown',
                  sessionId,
                  origin,
                  entry,
                  data: '',
                  step_name: 'main',
                }),
              );
            }}
            size={isMobile ? 'large' : 'medium'}
          />
        </Cell>
        <Cell span={isMobile ? 8 : 9}>
          <Input
            dataHook="phone-field"
            autocomplete="null"
            placeholder={t('brief-modal-phone-number-placeholder')}
            autoSelect={false}
            status={isPhoneNumberError ? 'error' : undefined}
            statusMessage={t(`brief-modal-phone-number-error-message`)}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            size={isMobile ? 'large' : 'medium'}
            ariaLabel="phone-number"
          />
        </Cell>
      </Layout>
    </FormField>
  );
};

export default PhoneNumberField;
