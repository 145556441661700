import React, { FC } from 'react';
import _ from 'lodash';
import { Text, Box, Layout, Cell } from '@wix/design-system';
import { Chat, ChatSmall } from '@wix/wix-ui-icons-common';
import { useProfileContext } from '../../Contexts/ProfileContext';

type Props = {
  isMobile?: boolean;
};

const ProviderLanguages: FC<Props> = ({ isMobile }) => {
  const {
    partnerProfile: { matchingPreferences },
    languages,
  } = useProfileContext();

  function getLanguages() {
    return _.map(matchingPreferences?.languages, (language) =>
      _.get(_.find(languages, { id: language }), 'value'),
    );
  }
  return (
    <Box verticalAlign="middle">
      <Layout gap="0px" alignItems="top">
        <Cell span={1}>
          <Box paddingRight="6px">{isMobile ? <Chat /> : <ChatSmall />}</Box>
        </Cell>
        <Cell span={11}>
          <Text size={isMobile ? 'small' : 'tiny'} weight="thin">
            {getLanguages().join(', ')}
          </Text>
        </Cell>
      </Layout>
    </Box>
  );
};

export default ProviderLanguages;
