import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Text, Divider } from '@wix/design-system';
import { NAVBAR_HEIGHT_OFFSET } from '../NavBar/NavBar';
import { useProfileContext } from '../../Contexts/ProfileContext';
import LinkWithHref from '../LinkWithHref';
import Star from '../../Images/Star';
import s from './ProviderReviewSummary.module.scss';

interface Props {
  isStickyMobileView: boolean;
}

const ProviderReviewSummary: FC<Props> = ({ isStickyMobileView }) => {
  const {
    partnerProfile: { providerRating },
  } = useProfileContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function renderReviewScore() {
    if (providerRating?.reviewsCount > 0) {
      return (
        <>
          <Box paddingTop={isStickyMobileView ? '3px' : '0px'}>
            <Text
              size={isStickyMobileView ? 'small' : 'medium'}
              weight={isStickyMobileView ? 'thin' : 'normal'}
              dataHook="average-review-rating"
              className={s.reviewText}
            >
              {Intl.NumberFormat(language, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }).format(providerRating.averageReviewRating)}
            </Text>
          </Box>
          &nbsp;
          <Box paddingTop={isStickyMobileView ? '2px' : '3px'}>
            <LinkWithHref
              href="#reviews"
              smooth
              spy
              to="reviews"
              offset={NAVBAR_HEIGHT_OFFSET}
            >
              <Text size="tiny" weight="thin" className={s.reviewText}>
                (
                {Intl.NumberFormat(language, {}).format(
                  providerRating.reviewsCount,
                )}
                )
              </Text>
            </LinkWithHref>
          </Box>
        </>
      );
    } else {
      return (
        <Box width="max-content" paddingTop="5px">
          <Text
            size="tiny"
            weight="thin"
            dataHook="no-reviews"
            className={`${s.noReviews} ${s.reviewTextColor}`}
          >
            {t('profile-no-reviews')}
          </Text>
        </Box>
      );
    }
  }
  return (
    <Box
      direction="horizontal"
      dataHook="provider-review-summary"
      alignContent="center"
    >
      <Star
        className={providerRating?.reviewsCount > 0 ? s[''] : s.noreviewsCount}
      />
      {renderReviewScore()}
      {!isStickyMobileView && (
        <>
          <Box
            height="15px"
            marginLeft="6px"
            marginRight="6px"
            paddingTop="4px"
          >
            <Divider direction="vertical" />
          </Box>
          <Box marginRight="4px">
            <Text size="medium">{providerRating?.completedProjects}</Text>
          </Box>
          <Box minWidth="130px" paddingTop="4px">
            <Text size="small" weight="thin" className={s.reviewTextColor}>
              {t('profile-completed-project.icu', {
                number: providerRating?.completedProjects,
              })}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProviderReviewSummary;
