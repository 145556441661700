import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Text, Tag, Box, TextButton } from '@wix/design-system';
import { useProfileContext } from '../../Contexts/ProfileContext';
import s from './Services.module.scss';

const MAX_TAGS_DESKTOP = 16;
const MAX_TAGS_MOBILE = 7;

type Props = {
  isMobile?: boolean;
};

const Services: FC<Props> = ({ isMobile }) => {
  const { t } = useTranslation();
  const [moreCount, setMoreCount] = useState<number>(0);
  const [showAll, setShowAll] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [renderedTags, setRenderedTags] = useState<any>([]);
  const { partnerProfile, services } = useProfileContext();

  useEffect(() => {
    const servicesList = _.flatMap(services, (category) => {
      return category.services;
    });
    const MAX_TAGS = isMobile ? MAX_TAGS_MOBILE : MAX_TAGS_DESKTOP;
    const foundServices = _.map(partnerProfile.services?.values, ({ id }) => {
      return {
        key: id,
        dataHook: `tag-service-${id}`,
        content: _.find(servicesList, { id })?.displayKey ?? undefined,
      };
    });
    const _services = _.filter(
      foundServices,
      (item) => !_.isUndefined(item.content),
    );
    if (showAll && _.size(_services) >= MAX_TAGS) {
      setMoreCount(0);
      setShowLess(true);
    }
    if (_.size(_services) >= MAX_TAGS && !showAll) {
      setRenderedTags(_services.slice(0, MAX_TAGS));
      setShowLess(false);
      setMoreCount(_.size(_services) - MAX_TAGS);
    } else {
      setMoreCount(0);
      setRenderedTags([..._services]);
    }
  }, [partnerProfile, services, showAll]);

  function renderTag({
    key,
    dataHook,
    content,
  }: {
    key: string;
    dataHook: string;
    content: string;
  }) {
    return (
      <Tag
        key={key}
        dataHook={dataHook}
        id="standard"
        theme="lightOutlined"
        removable={false}
      >
        {content}
      </Tag>
    );
  }

  if (_.size(renderedTags) === 0) {
    return <></>;
  }

  return (
    <Box direction="vertical" marginTop="36px" maxWidth="770px">
      <Text weight="bold">{t('profile-services')}</Text>
      <Box
        marginTop="18px"
        marginBottom="20px"
        marginRight="50px"
        dataHook="services-tags"
        width="100%"
      >
        <div data-hook="selection-tags-container" className={s.root}>
          {_.map(renderedTags, (tag: any) => {
            return renderTag({
              ...tag,
              content: t(`explore.services.${tag.content}`),
            });
          })}
          {moreCount > 0 && (
            <Box>
              <TextButton
                underline="always"
                dataHook="tag-more-count"
                skin="dark"
                size="tiny"
                weight="normal"
                onClick={() => {
                  setShowAll(true);
                }}
              >
                {t('fliters-more-count', {
                  moreCount,
                })}
              </TextButton>
            </Box>
          )}
          {showLess && (
            <>
              <TextButton
                underline="always"
                dataHook="tag-show-less"
                weight="normal"
                skin="dark"
                size="tiny"
                onClick={() => {
                  setShowAll(false);
                  setShowLess(false);
                }}
              >
                {t('fliters-show-less')}
              </TextButton>
            </>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default Services;
