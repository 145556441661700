import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Text, Box, Layout, Cell } from '@wix/design-system';
import { Location, LocationSmall } from '@wix/wix-ui-icons-common';
import { useProfileContext } from '../../Contexts/ProfileContext';
import { generateLocationLabel } from '../../../utils/locationLabel';

type Props = {
  showTitle?: boolean;
  isMobile?: boolean;
  marginTop?: string;
};

const ProviderLocation: FC<Props> = ({ showTitle, marginTop, isMobile }) => {
  const { partnerProfile } = useProfileContext();

  const { t } = useTranslation();

  return (
    <>
      {showTitle && (
        <Text weight="bold">{t('profile-page-location-and-language')}</Text>
      )}
      <Box verticalAlign="middle" marginTop={marginTop}>
        <Layout gap="0px" alignItems="center">
          <Cell span={1}>
            <Box paddingRight="6px">
              {isMobile ? <Location /> : <LocationSmall />}
            </Box>
          </Cell>
          <Cell span={11}>
            <Text
              showTooltip
              ellipsis
              maxLines={1}
              size="small"
              weight="thin"
              dataHook="provider-location"
            >
              {partnerProfile.address &&
                generateLocationLabel(partnerProfile.address)}
            </Text>
          </Cell>
        </Layout>
      </Box>
    </>
  );
};

export default ProviderLocation;
