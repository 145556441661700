import { CommonAddress } from '@wix/ambassador-wix-atlas-service-web/types';

export const generateLocationLabel = (address: CommonAddress) => {
  const city = address?.city;
  const country = address?.countryFullname ?? address.country;
  const state = address?.subdivision;
  const stateName = address?.subdivisions?.[0]?.name ?? state;

  const localArea = state && stateName ? stateName : city;

  const locationLabel =
    localArea && country
      ? `${localArea}, ${country}`
      : localArea
      ? `${localArea}`
      : `${country}`;
  return locationLabel;
};
