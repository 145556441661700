import React, { FC, useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { useTranslation, Trans } from '@wix/fe-essentials-standalone';
import {
  Box,
  Text,
  Heading,
  Accordion,
  accordionItemBuilder,
  TextButton,
} from '@wix/design-system';
import { ErrorBoundary } from 'react-error-boundary';
import { ChevronDown, ChevronUp } from '@wix/wix-ui-icons-common';
import { useProfileContext } from '../../Contexts/ProfileContext';
import ErrorFallback from '../../ErrorFallback';
import useFormattedCurrency from '../../Hooks/useFormattedCurrency';
import s from './StartingRates.module.scss';

type Props = {
  isMobileView?: boolean;
};
const StartingRates: FC<Props> = ({ isMobileView }) => {
  const { t } = useTranslation();
  const { offeredServices, userCurrency, currencyConversionRate } =
    useProfileContext();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [firstAccordionRowIsOpen, setFirstAccordionRowIsOpen] = useState(true);

  const { startingPriceFormatter } = useFormattedCurrency({
    currency: userCurrency,
    conversionRate: currencyConversionRate,
  });

  const startingPriceRow = ({
    service,
    end = false,
  }: {
    service: any;
    end: boolean;
  }) => {
    return (
      <div tabIndex={0}>
        <Box
          key={service.id}
          paddingLeft="24px"
          paddingRight="10px"
          paddingTop="18px"
          paddingBottom="18px"
          align="space-between"
          borderBottom={end ? '' : '1px solid #DFE5EB'}
          dataHook={`service-${service.id}`}
          minWidth="fit-content"
        >
          <Box direction="vertical" minWidth="172px">
            <Text tagName="h4" className={s.headerLabel}>
              {t(`explore.services.${service.displayKey}`)}
            </Text>
            <Text weight="thin" size="small" light secondary>
              {t(`profile-page-${service.displayKey}-description`)}
            </Text>
          </Box>
          <Box
            direction="vertical"
            dataHook="starting-price"
            minWidth="130px"
            paddingLeft="18px"
          >
            <Trans
              i18nKey="profile-page-starting-rates-min-price"
              values={{
                amount: startingPriceFormatter(service.minPrice),
              }}
            >
              <Text weight="thin" size="tiny" light secondary>
                {t('profile-page-starting-rates-Starts-at')}
              </Text>
              <Text weight="thin">
                {startingPriceFormatter(service.minPrice)}
              </Text>
            </Trans>
          </Box>
        </Box>
      </div>
    );
  };

  const renderCategory = (category: any, index: number) => {
    const isSelected = index === selectedIndex;
    let classes = `${s.categoryRow} `;
    if (isSelected) {
      classes += `${s.selected} `;
    }

    return (
      <div
        tabIndex={0}
        key={category.id}
        className={classes}
        onClick={() => {
          setSelectedIndex(index);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setSelectedIndex(index);
          }
        }}
      >
        <Box padding="12px">
          <Text tagName="h3" className={s.headerLabel}>{`${t(
            `explore.services.${category.displayKey}`,
          )} (${_.size(category.services)})`}</Text>
        </Box>
      </div>
    );
  };

  const renderServicesWithoutCategories = () => {
    return (
      <Box
        paddingTop="18px"
        direction="vertical"
        dataHook="services-without-categories"
      >
        <Box
          direction="vertical"
          border="1px solid #DFE5EB"
          width="100%"
          borderRadius="8px"
        >
          {_.map(
            offeredServices?.[selectedIndex]?.services,
            (service, index) => {
              if (
                parseInt(index, 10) ===
                offeredServices?.[selectedIndex]?.services.length - 1
              ) {
                return startingPriceRow({ service, end: true });
              }
              return startingPriceRow({ service, end: false });
            },
          )}
        </Box>
      </Box>
    );
  };

  const renderCategoriesAndServices = () => {
    return (
      <Box
        dataHook="categories-and-services"
        marginTop="18px"
        width="100%"
        border="1px solid #DFE5EB"
        borderRadius="8px"
        marginRight="30px"
        overflow="hidden"
      >
        <Box
          direction="vertical"
          maxWidth="20vw"
          width="100%"
          borderRight="1px solid #DFE5EB"
        >
          {_.map(offeredServices, (category, index) =>
            renderCategory(category, index),
          )}
        </Box>
        <Box
          direction="vertical"
          width="100%"
          overflow-x="scroll"
          className={s.scrollBar}
        >
          {_.map(
            offeredServices?.[selectedIndex]?.services,
            (service, index) => {
              if (
                parseInt(index, 10) ===
                offeredServices?.[selectedIndex]?.services.length - 1
              ) {
                return startingPriceRow({ service, end: true });
              }
              return startingPriceRow({ service, end: false });
            },
          )}
        </Box>
      </Box>
    );
  };

  const renderAccordionItem = useCallback(
    ({ service, isLastServiceAndCategory, useSmallerBottomPadding }: any) => {
      return (
        <>
          <Box
            dataHook="accordion-service"
            direction="vertical"
            paddingLeft="18px"
            paddingRight="18px"
            paddingTop="15px"
            paddingBottom="15px"
            border="1px solid #DFE5EB"
            borderRadius="8px"
          >
            <Text size="medium" weight="thin" secondary>
              {t(`explore.services.${service.displayKey}`)}
            </Text>
            <Text size="small" weight="thin" light secondary>
              {t(`profile-page-${service.displayKey}-description`)}
            </Text>
            <Box height="12px" />
            <Text size="tiny" weight="thin" secondary>
              {t('profile-page-mobile-starting-rate-price', {
                amount: startingPriceFormatter(service.minPrice),
              })}
            </Text>
          </Box>
          {!isLastServiceAndCategory && (
            <Box height={useSmallerBottomPadding ? '6px' : '12px'} />
          )}
        </>
      );
    },
    [startingPriceFormatter, t],
  );

  const items = useMemo(() => {
    if (isMobileView) {
      return _.map(offeredServices, (category, categoryIndex) => {
        const isFirstItem = categoryIndex === 0;
        return accordionItemBuilder({
          initiallyOpen: isFirstItem, // WSR Bug with this prop, hence open/toggle logic
          ...(isFirstItem && {
            open: firstAccordionRowIsOpen,
            onToggle: () => {
              setFirstAccordionRowIsOpen(!firstAccordionRowIsOpen);
            },
          }),
          title: (
            <Text size="medium" dataHook={`accordion-title-${categoryIndex}`}>
              {`${t(`explore.services.${category.displayKey}`)} (${_.size(
                category.services,
              )})`}
            </Text>
          ),
          buttonType: 'node',
          showLabel: 'always',
          expandLabel: (
            <TextButton>
              <ChevronDown color="black" />
            </TextButton>
          ),
          collapseLabel: (
            <TextButton>
              <ChevronUp color="black" />
            </TextButton>
          ),
          children: (
            <>
              {_.map(category?.services, (service, serviceIndex) => {
                const isLastCategory =
                  categoryIndex === _.size(offeredServices) - 1;
                const isLastService =
                  parseInt(serviceIndex, 10) === _.size(category?.services) - 1;

                return (
                  <>
                    {renderAccordionItem({
                      service,
                      isLastServiceAndCategory: isLastCategory && isLastService,
                      useSmallerBottomPadding: isLastService,
                    })}
                  </>
                );
              })}
            </>
          ),
        });
      });
    }
    return [];
  }, [
    isMobileView,
    offeredServices,
    firstAccordionRowIsOpen,
    renderAccordionItem,
    t,
  ]);

  if (isMobileView) {
    return (
      <Box direction="vertical" dataHook="mobile-starting-rates">
        <Heading size="medium" as="h2">
          {t('profile-page-starting-rates-header')}
        </Heading>
        <Box height="18px" />
        {_.size(offeredServices) === 1 ? (
          <div data-hook="mobile-one-category-starting-rates">
            {_.map(_.head(offeredServices)?.services, (service, i) => {
              const isLast =
                parseInt(i, 10) === _.size(_.head(offeredServices)?.services);
              return (
                <>
                  {renderAccordionItem({
                    service,
                    isLastServiceAndCategory: isLast,
                    useSmallerBottomPadding: false,
                  })}
                </>
              );
            })}
          </div>
        ) : (
          <Accordion
            dataHook="starting-rates-accordion"
            size="tiny"
            skin="light"
            hideShadow
            horizontalPadding="none"
            multiple
            items={items || []}
          />
        )}
      </Box>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box direction="vertical" dataHook="starting-rates">
        <Heading size="large">
          {t('profile-page-starting-rates-header')}
        </Heading>
        {_.size(offeredServices) === 1
          ? renderServicesWithoutCategories()
          : renderCategoriesAndServices()}
      </Box>
    </ErrorBoundary>
  );
};

export default StartingRates;
