import { ClientProject, ReviewData } from '@common/types';
import { Category, Service } from '@wix/ambassador-category-services/types';
import _ from 'lodash';

export const getReviewDataFromProjects: (
  projects: ClientProject[],
) => ReviewData[] = (projects: ClientProject[]) => {
  return projects.map((project: ClientProject) => {
    return {
      ...project.review,
      clientName: project.clientName,
      serviceId: project.serviceId,
      url: project.url,
      id: project.id,
      revision: project.revision,
    };
  });
};

export const getServicesFromCategories = (categories: Category[] | undefined) =>
  categories
    ? (_.flatMap(categories, (category) => {
        return category.services;
      }) as Service[])
    : [];
