import _ from 'lodash';
import React, { FC } from 'react';
import {
  Text,
  Modal,
  CustomModalLayout,
  Layout,
  Cell,
  Divider,
  TextButton,
  Loader,
  FloatingNotification,
  Box,
  Input,
  InputArea,
  FormField,
} from '@wix/design-system';
import { InfoCircle } from '@wix/wix-ui-icons-common';
import { marketplaceBriefGeneralAction } from '@wix/bi-logger-marketplace/v2';
import { useTranslation, Trans } from '@wix/fe-essentials-standalone';
import { TERMS_OF_USE_URL } from '@utils/constants';
import { useBi } from '@components/Hooks/useBi';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import PhoneNumberField from '../PhoneNumberField';
import CompactBriefServices from '../CompactBriefServices';
import LanguagePickerField from '../LanguagePickerField';
import LoadingCompactBrief from '../LoadingCompactBrief/LoadingCompactBrief';
import s from './CompactBriefDesktop.module.scss';

type Props = {
  isOpen: boolean;
  closeModal: (exit?: string) => void;
  setPhoneNumberWithCountry: (val: string) => void;
  onSubmitBrief: () => void;
  onChangeService: (optionId: string) => void;
  setMoreDetails: (val: string) => void;
  moreDetails: string;
  onChangeName: (e: any) => void;
  submitInProgress: boolean;
  name: string;
  setPreferredLanguage: (val: string) => void;
  preferredLanguage: string;
  selectedService: string;
  showServiceError: boolean;
  showNameError: boolean;
  setIsPhoneLegit: (val: boolean) => void;
  isBriefLoading: boolean;
  phoneNumberWithCountry: string;
  userGUIDFromURL: string | null;
  refillBriefIsLoading: boolean;
  briefTimedOutMessage: boolean;
};
const CompactBriefDesktop: FC<Props> = ({
  isOpen,
  closeModal,
  setPhoneNumberWithCountry,
  submitInProgress,
  onSubmitBrief,
  onChangeService,
  onChangeName,
  setMoreDetails,
  moreDetails,
  name,
  preferredLanguage,
  setPreferredLanguage,
  selectedService,
  showServiceError,
  showNameError,
  setIsPhoneLegit,
  isBriefLoading,
  phoneNumberWithCountry,
  userGUIDFromURL,
  refillBriefIsLoading,
  briefTimedOutMessage,
}) => {
  const { t } = useTranslation();
  const { sessionId } = useBiSessionContext();
  const { userDetails, partnerProfile, origin, entry } = useProfileContext();
  const logger = useBi();
  const partnerName = partnerProfile?.professionalInformation?.businessName;
  const email = _.get(userDetails, 'email');

  return (
    <>
      <Modal isOpen={isOpen} screen="full">
        {isBriefLoading ? (
          <Box
            backgroundColor="#FFF"
            direction="vertical"
            align="center"
            verticalAlign="middle"
            width="100%"
            height="100vh"
          >
            <Loader size="large"></Loader>
            <Box paddingTop="12px">
              <Text size="medium" weight="bold">
                {t('brief-loading-login-page')}
              </Text>
            </Box>
          </Box>
        ) : (
          <CustomModalLayout
            className={s.root}
            showFooterDivider={true}
            dataHook="compact-brief"
            width="900px"
            title={t('brief-modal-title', { partnerName })}
            primaryButtonText={
              submitInProgress ? null : t(`brief-modal-submit`)
            }
            primaryButtonProps={{
              children: <Loader size="tiny" />,
              disabled: submitInProgress,
            }}
            secondaryButtonText={t(`brief-modal-cancel`)}
            primaryButtonOnClick={() => onSubmitBrief()}
            secondaryButtonOnClick={closeModal}
            onCloseButtonClick={() => closeModal()}
            sideActions={
              <Trans i18nKey="brief-modal-footer">
                <Text className={s.footerText} size="tiny">
                  1
                </Text>
                <TextButton
                  skin="dark"
                  className={s.footerText}
                  dataHook="brief-terms-conditions"
                  size="tiny"
                  as="a"
                  href={TERMS_OF_USE_URL}
                  target="_blank"
                  underline="always"
                  onClick={() => {
                    logger.report(
                      marketplaceBriefGeneralAction({
                        briefType: 'compact',
                        action_type: 'terms & conditions',
                        sessionId,
                        origin,
                        entry,
                        data: '',
                        step_name: 'main',
                      }),
                    );
                  }}
                >
                  2
                </TextButton>
                <Text size="tiny" className={s.footerText}>
                  3
                </Text>
              </Trans>
            }
          >
            {refillBriefIsLoading ? (
              <LoadingCompactBrief isMobile={false} />
            ) : (
              <Layout>
                {briefTimedOutMessage && (
                  <Cell span={12}>
                    <FloatingNotification
                      dataHook="brief-timed-out-message"
                      prefixIcon={<InfoCircle />}
                      type="destructive"
                      width="100%"
                      showCloseButton={false}
                      text={t('brief-modal-timed-out-message')}
                    />
                  </Cell>
                )}
                <Cell span={12}>
                  <CompactBriefServices
                    onChangeService={onChangeService}
                    selectedService={selectedService}
                    showServiceError={showServiceError}
                    isMobile={false}
                  />
                </Cell>
                <Cell span={12}>
                  <FormField label={t('brief-modal-more-details-title')}>
                    <InputArea
                      size="medium"
                      placeholder={t('brief-modal-more-details-placeholder')}
                      maxLength={1000}
                      minHeight={'108'}
                      hasCounter
                      value={moreDetails}
                      onChange={(event: any) =>
                        setMoreDetails(event.target.value)
                      }
                    />
                  </FormField>
                </Cell>
                <Cell span={12}>
                  <Divider direction="horizontal" />
                </Cell>
                <Cell span={6}>
                  <FormField label={t('brief-modal-full-name-title')} required>
                    <Input
                      dataHook="brief-full-name"
                      value={name}
                      onChange={onChangeName}
                      status={showNameError ? 'error' : undefined}
                      statusMessage={t('brief-modal-name-error-message')}
                    />
                  </FormField>
                </Cell>
                {email ? (
                  <Cell span={6}>
                    <FormField
                      label={t('brief-modal-email-title')}
                      dataHook="brief-email-field"
                    >
                      <Input dataHook="email-input" disabled value={email} />
                    </FormField>
                  </Cell>
                ) : (
                  <></>
                )}

                <Cell span={6}>
                  <LanguagePickerField
                    setPreferredLanguage={setPreferredLanguage}
                    preferredLanguage={preferredLanguage}
                    isMobile={false}
                  />
                </Cell>
                <Cell span={6}>
                  <PhoneNumberField
                    setPhoneNumberWithCountry={setPhoneNumberWithCountry}
                    setIsPhoneLegit={setIsPhoneLegit}
                    isMobile={false}
                    phoneNumberWithCountry={phoneNumberWithCountry}
                    userGUIDFromURL={userGUIDFromURL}
                  />
                </Cell>
                <Cell span={12}>
                  <Divider direction="horizontal" />
                </Cell>
              </Layout>
            )}
          </CustomModalLayout>
        )}
      </Modal>
    </>
  );
};

export default CompactBriefDesktop;
