import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import {
  marketplaceBriefGeneralAction,
  submitBrief74,
  newBriefLoaded73,
} from '@wix/bi-logger-marketplace/v2';
import { useErrorMonitor, useHttpClient } from '@wix/fe-essentials-standalone';
import { useBi } from '@components/Hooks/useBi';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import { useHeaderContext } from '@components/Contexts/HeaderContext';
import { SUBMIT_BRIEF_DIRECT_URL } from '@utils/constants';
import { getWixLoginUrl } from '@utils/urlUtils';
import CompactBriefDesktop from '@components/CompactBrief/CompactBriefDesktop';
import CompactBriefMobile from '@components/CompactBrief/CompactBriefMobile';

export const USER_GUID = 'userGUID';

export type CompactBriefProps = {
  isOpen: boolean;
  closeModal: (exitType?: string) => void;
  setShowSuccessNotification: (shouldShow: boolean) => void;
  setShowErrorNotification: (shouldShow: boolean) => void;
  setBriefTimedOutMessage: (shouldShow: boolean) => void;
  isMobileView: boolean;
  briefTimedOutMessage: boolean;
};

const CompactBrief: FC<CompactBriefProps> = (props) => {
  const {
    isOpen,
    closeModal,
    setShowSuccessNotification,
    setShowErrorNotification,
    isMobileView,
    setBriefTimedOutMessage,
  } = props;
  const { sessionId } = useBiSessionContext();
  const {
    partnerProfile: { id: partnerId },
    origin,
    entry,
    language,
  } = useProfileContext();
  const { userDetails } = useHeaderContext();
  const [moreDetails, setMoreDetails] = useState('');
  const [name, setName] = useState('');
  const [phoneNumberWithCountry, setPhoneNumberWithCountry] =
    useState<string>('');
  const [preferredLanguage, setPreferredLanguage] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [showServiceError, setShowServiceError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [isPhoneLegit, setIsPhoneLegit] = useState(true);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [isNameLoggerSent, setIsNameLoggerSent] = useState(false);
  const [isBriefLoading, setIsBriefLoading] = useState(false);
  const [userGUIDFromURL, setUserGUIDFromURL] = useState<string | null>('');
  const [refillBriefIsLoading, setRefillBriefIsLoading] = useState(false);

  const logger = useBi();
  const httpClient = useHttpClient();
  const errorMonitor = useErrorMonitor();

  const email = _.get(userDetails, 'email');

  useEffect(() => {
    setShowServiceError(false);
    setIsPhoneLegit(true);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setUserGUIDFromURL(params.get(USER_GUID));
  }, []);

  async function getBriefPayload() {
    if (userGUIDFromURL) {
      try {
        const {
          data: {
            value: { jobBriefInfo },
          },
        } = await httpClient.post<any>(
          `/api/compactBriefPayload/getCompactBriefPayload`,
          { key: userGUIDFromURL },
        );
        return jobBriefInfo;
      } catch (e) {
        errorMonitor.captureException(e as Error);
      }
    }
  }

  function addUserGUIDToURL(guid: string) {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(USER_GUID, guid);
    window.history.replaceState(history.state, '', currentUrl);
    window.location.assign(
      `${getWixLoginUrl(language)}&redirectTo=${encodeURIComponent(
        `${window.location.href.replace('#brief', '#')}`,
      )}&originUrl=${encodeURIComponent(`${currentUrl} `)}`,
    );
  }

  function deleteUserGUIDFromURL() {
    if (userGUIDFromURL) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete(USER_GUID);
      window.history.replaceState(
        history.state,
        window.location.href,
        currentUrl,
      );
      window.location.replace(currentUrl);
      setUserGUIDFromURL('');
    }
  }

  async function submitBriefAfterLogin(jobBriefInfo: any) {
    setSubmitInProgress(true);
    try {
      const {
        phone,
        jobBrief: { languages, name, additionalInfo, serviceId },
      } = jobBriefInfo;
      const language = languages[0];
      const {
        data: { briefId },
      } = await httpClient.post<any>(SUBMIT_BRIEF_DIRECT_URL, {
        providerId: partnerId,
        name,
        phone,
        serviceId,
        language,
        description: additionalInfo,
      });

      logger.report(
        submitBrief74({
          briefType: isMobileView ? 'compact_mobile' : 'compact',
          sessionId,
          origin,
          entry,
          briefId,
          data: JSON.stringify({
            briefInfo: {
              phone,
              language,
              name,
              description: additionalInfo,
              serviceId,
            },
          }),
        }),
      );

      if (userGUIDFromURL) {
        deleteBriefPayloadByGUID();
      }

      closeModal('submit');
      setShowSuccessNotification(true);
      setBriefTimedOutMessage(false);
    } catch (e) {
      closeModal();
      setShowErrorNotification(true);
      errorMonitor.captureException(e as Error);
    }
    deleteUserGUIDFromURL();
  }

  useEffect(() => {
    const refillBriefWithPayLoad = async () => {
      if (userGUIDFromURL && _.includes(window.location.hash, '#brief')) {
        setRefillBriefIsLoading(true);

        const response = await getBriefPayload();
        if (response) {
          const phone = response.phone;
          const jobBrief = response.jobBrief;

          setMoreDetails(jobBrief.additionalInfo || '');
          setName(jobBrief.name);
          setPhoneNumberWithCountry(phone);
          setPreferredLanguage(jobBrief.languages[0]);
          setSelectedService(jobBrief.serviceId);
        }
        setRefillBriefIsLoading(false);
        setBriefTimedOutMessage(false);
      }
    };
    refillBriefWithPayLoad();
  }, [userGUIDFromURL]);

  useEffect(() => {
    const addEmailAndSubmit = async () => {
      if (userGUIDFromURL && userDetails) {
        const response = await getBriefPayload();
        if (response) {
          const jobBriefInfo = addEmailToPayload(response);
          submitBriefAfterLogin(jobBriefInfo);
        } else {
          setBriefTimedOutMessage(true);
          if (!_.includes(window.location.hash, '#brief')) {
            history.replaceState(history.state, '', '#brief');
            deleteUserGUIDFromURL();
          }
        }
      }
    };
    addEmailAndSubmit();
  }, [userDetails, userGUIDFromURL]);

  function addEmailToPayload(response: any) {
    const jobBriefInfo = response;
    const answers = JSON.parse(response.jobBrief.answers);
    const answersWithEmail = answers.map((field: any) => {
      if (field.type === 'contactForm') {
        return { ...field, answer: email };
      } else {
        return field;
      }
    });
    jobBriefInfo.email = email;
    jobBriefInfo.jobBrief.answers = JSON.stringify(answersWithEmail);
    return jobBriefInfo;
  }

  function buildAnswersPropertyFromPayload() {
    const answers: Array<any> = [];
    try {
      _.assign(answers, [
        {
          type: 'additionalInfo',
          briefSummary: null,
          answer: moreDetails,
        },
        {
          type: 'inputField',
          briefSummary: {
            group: 'clientDetails',
            label: 'brief-clientdetails-label-name',
            order: 1,
          },
          answer: name,
        },
        {
          type: 'languagePicker',
          briefSummary: {
            group: 'moreInfo',
            label: 'brief-preference-label-language',
            order: 2,
          },
          answer: [preferredLanguage],
        },
        {
          type: 'phone',
          briefSummary: null,
          answer: phoneNumberWithCountry,
        },
        {
          type: 'contactForm',
          briefSummary: null,
          answer: email,
        },
      ]);
    } catch (e) {
      errorMonitor.captureException(e as Error);
    }
    return answers;
  }

  const onSubmitBrief = async () => {
    logger.report(
      marketplaceBriefGeneralAction({
        briefType: isMobileView ? 'compact_mobile' : 'compact',
        action_type: 'click submit',
        sessionId,
        origin,
        entry,
        step_name: 'main',
      }),
    );
    setShowNameError(!name);
    setShowServiceError(!selectedService);
    if (selectedService && name && isPhoneLegit) {
      const jobBriefInfo = {
        jobBrief: {
          answers: JSON.stringify(buildAnswersPropertyFromPayload()),
          additionalInfo: moreDetails,
          name,
          languages: [preferredLanguage],
          entryPoint: 'compactBrief',
          serviceId: selectedService,
          sessionId,
        },
        phone: phoneNumberWithCountry,
        email,
      };
      if (email) {
        setSubmitInProgress(true);
        submitBriefAfterLogin(jobBriefInfo);
      } else {
        handleLoggedoutUser(jobBriefInfo);
      }
    }
  };

  const handleLoggedoutUser = async (jobBriefInfo: any) => {
    setIsBriefLoading(true);
    try {
      const { data: briefPayload } = await httpClient.post<any>(
        `/api/compactBriefPayload/setCompactBriefPayload`,
        { jobBriefInfo, sessionId, partnerId },
      );
      addUserGUIDToURL(briefPayload);
      setTimeout(() => {
        setIsBriefLoading(false);
      }, 6000);
    } catch (e) {
      errorMonitor.captureException(e as Error);
    }
  };

  const deleteBriefPayloadByGUID = async () => {
    try {
      await httpClient.delete<any>(
        `/api/compactBriefPayload/deleteCompactBriefPayload?key=${userGUIDFromURL}`,
      );
    } catch (e) {
      errorMonitor.captureException(e as Error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      logger.report(
        newBriefLoaded73({
          briefType: isMobileView ? 'compact_mobile' : 'compact',
          sessionId,
          origin: origin || document.referrer,
          entry,
          proGuid: partnerId,
        }),
      );
    }
  }, [entry, isOpen, origin, sessionId, isMobileView, partnerId]);

  useEffect(() => {
    if (isOpen) {
      setPreferredLanguage('');
      setSelectedService('');
      setMoreDetails('');
      setName('');
      setPhoneNumberWithCountry('');
      setShowSuccessNotification(false);
      setShowServiceError(false);
      setShowNameError(false);
      setIsPhoneLegit(true);
      setSubmitInProgress(false);
    }
  }, [isOpen]);

  const onChangeName = (e: any) => {
    if (!isNameLoggerSent) {
      logger.report(
        marketplaceBriefGeneralAction({
          briefType: isMobileView ? 'compact_mobile' : 'compact',
          action_type: 'type client name',
          sessionId,
          origin,
          entry,
          data: 'true',
          step_name: 'main',
        }),
      );
      setIsNameLoggerSent(true);
    }
    setName(e.target.value);
    setShowNameError(!e.target.value);
  };
  const onChangeService = (optionId: string) => {
    logger.report(
      marketplaceBriefGeneralAction({
        briefType: isMobileView ? 'compact_mobile' : 'compact',
        action_type: 'choose service',
        sessionId,
        origin,
        entry,
        data: JSON.stringify({ selectedServiceId: optionId }),
        step_name: 'main',
      }),
    );
    setSelectedService(optionId);
    setShowServiceError(!optionId);
  };

  const uiProps = {
    ...props,
    onChangeName,
    onChangeService,
    onSubmitBrief,
    setPhoneNumberWithCountry,
    submitInProgress,
    setMoreDetails,
    moreDetails,
    name,
    selectedService,
    setPreferredLanguage,
    preferredLanguage,
    showServiceError,
    showNameError,
    setIsPhoneLegit,
    isBriefLoading,
    phoneNumberWithCountry,
    userGUIDFromURL,
    refillBriefIsLoading,
  };
  return (
    <>
      {isMobileView ? (
        <CompactBriefMobile {...uiProps} />
      ) : (
        <CompactBriefDesktop {...uiProps} />
      )}
    </>
  );
};

export default CompactBrief;
