import _ from 'lodash';
import React, { FC } from 'react';
import {
  Text,
  Modal,
  Box,
  Cell,
  Divider,
  TextButton,
  Loader,
  SectionHelper,
  Layout,
  Input,
  InputArea,
  FormField,
  CloseButton,
  Button,
} from '@wix/design-system';
import { InfoCircle } from '@wix/wix-ui-icons-common';
import { marketplaceBriefGeneralAction } from '@wix/bi-logger-marketplace/v2';
import { useTranslation, Trans } from '@wix/fe-essentials-standalone';
import { TERMS_OF_USE_URL } from '@utils/constants';
import { useBi } from '@components/Hooks/useBi';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import PhoneNumberField from '../PhoneNumberField';
import CompactBriefServices from '../CompactBriefServices';
import LanguagePickerField from '../LanguagePickerField';
import LoadingCompactBrief from '../LoadingCompactBrief/LoadingCompactBrief';
import s from './CompactBriefMobile.module.scss';

type Props = {
  isOpen: boolean;
  closeModal: (exit?: string) => void;
  setPhoneNumberWithCountry: (val: string) => void;
  onSubmitBrief: () => void;
  onChangeService: (optionId: string) => void;
  setMoreDetails: (val: string) => void;
  moreDetails: string;
  onChangeName: (e: any) => void;
  submitInProgress: boolean;
  name: string;
  setPreferredLanguage: (val: string) => void;
  preferredLanguage: string;
  selectedService: string;
  showServiceError: boolean;
  showNameError: boolean;
  setIsPhoneLegit: (val: boolean) => void;
  isBriefLoading: boolean;
  phoneNumberWithCountry: string;
  userGUIDFromURL: string | null;
  refillBriefIsLoading: boolean;
  briefTimedOutMessage: boolean;
};
const CompactBriefMobile: FC<Props> = ({
  isOpen,
  closeModal,
  setPhoneNumberWithCountry,
  submitInProgress,
  onSubmitBrief,
  onChangeService,
  onChangeName,
  setMoreDetails,
  moreDetails,
  name,
  preferredLanguage,
  setPreferredLanguage,
  selectedService,
  showServiceError,
  showNameError,
  setIsPhoneLegit,
  isBriefLoading,
  phoneNumberWithCountry,
  userGUIDFromURL,
  refillBriefIsLoading,
  briefTimedOutMessage,
}) => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { userDetails, partnerProfile, origin, entry } = useProfileContext();
  const partnerName = partnerProfile?.professionalInformation?.businessName;
  const email = _.get(userDetails, 'email');

  const content = (
    <Box direction="vertical" marginTop="10px" gap="30px">
      {briefTimedOutMessage && (
        <Cell span={12}>
          <SectionHelper
            appearance="danger"
            showCloseButton={false}
            dataHook="brief-timed-out-message-mobile"
          >
            <Layout alignItems="top">
              <Cell span={1}>
                <InfoCircle />
              </Cell>
              <Cell span={11}>
                <Box paddingTop="3px">{t('brief-modal-timed-out-message')}</Box>
              </Cell>
            </Layout>
          </SectionHelper>
        </Cell>
      )}
      <Cell span={12}>
        <CompactBriefServices
          onChangeService={onChangeService}
          selectedService={selectedService}
          showServiceError={showServiceError}
          isMobile={true}
        />
      </Cell>
      <Cell span={12}>
        <FormField label={t('brief-modal-more-details-title')}>
          <InputArea
            size="medium"
            placeholder={t('brief-modal-more-details-placeholder')}
            maxLength={1000}
            hasCounter
            value={moreDetails}
            onChange={(event: any) => setMoreDetails(event.target.value)}
            minHeight="90px"
          />
        </FormField>
      </Cell>
      <Cell span={12}>
        <Divider direction="horizontal" />
      </Cell>
      <Cell span={6}>
        <FormField
          label={t('brief-modal-full-name-title')}
          dataHook="brief-full-name-mobile-form-field"
          required
        >
          <Input
            value={name}
            onChange={(e: any) => onChangeName(e)}
            status={showNameError ? 'error' : undefined}
            statusMessage={t('brief-modal-name-error-message')}
            dataHook="brief-full-name-mobile-input"
            size="large"
          />
        </FormField>
      </Cell>
      {email ? (
        <Cell span={12}>
          <FormField label={t('brief-modal-email-title')} required>
            <Input disabled value={email} size="large" />
          </FormField>
        </Cell>
      ) : (
        <></>
      )}
      <Cell span={12}>
        <LanguagePickerField
          setPreferredLanguage={setPreferredLanguage}
          preferredLanguage={preferredLanguage}
          isMobile={true}
        />
      </Cell>
      <Box paddingBottom="25px">
        <Cell span={12}>
          <PhoneNumberField
            setPhoneNumberWithCountry={setPhoneNumberWithCountry}
            setIsPhoneLegit={setIsPhoneLegit}
            isMobile={true}
            phoneNumberWithCountry={phoneNumberWithCountry}
            userGUIDFromURL={userGUIDFromURL}
          />
        </Cell>
      </Box>
    </Box>
  );
  const footer = (
    <Trans i18nKey="brief-modal-footer">
      <Text size="tiny" className={s.grayText}>
        1
      </Text>
      <TextButton
        skin="dark"
        size="tiny"
        dataHook="brief-terms-conditions"
        as="a"
        href={TERMS_OF_USE_URL}
        target="_blank"
        underline="always"
        className={s.grayText}
        onClick={() => {
          logger.report(
            marketplaceBriefGeneralAction({
              briefType: 'compact_mobile',
              action_type: 'terms & conditions',
              sessionId,
              origin,
              entry,
              data: '',
              step_name: 'main',
            }),
          );
        }}
      >
        2
      </TextButton>
      <Text size="tiny" className={s.grayText}>
        3
      </Text>
    </Trans>
  );

  return (
    <Modal isOpen={isOpen} screen="full">
      {isBriefLoading ? (
        <Box
          backgroundColor="#FFF"
          direction="vertical"
          align="center"
          verticalAlign="middle"
          width="100%"
          height="100vh"
        >
          <Loader size="large"></Loader>
          <Box paddingTop="12px">
            <Text size="medium" weight="bold">
              {t('brief-loading-login-page')}
            </Text>
          </Box>
        </Box>
      ) : (
        <Box
          dataHook="mobile-compact-brief"
          direction="vertical"
          className={s.modal}
        >
          <div>
            <Box
              paddingTop="20px"
              paddingBottom="20px"
              align="space-between"
              verticalAlign="middle"
            >
              <Box
                verticalAlign="middle"
                paddingTop="5px"
                paddingBottom="5px"
                paddingLeft="20px"
                paddingRight="20px"
              >
                <Text weight="bold">
                  {t('brief-modal-title', { partnerName })}
                </Text>
              </Box>
              <Box marginRight="10px">
                <CloseButton
                  dataHook="close-mobile-brief"
                  size="large"
                  onClick={() => closeModal()}
                />
              </Box>
            </Box>
          </div>
          <div className={s.modal__content} id="compactBriefMobile">
            {refillBriefIsLoading ? (
              <LoadingCompactBrief isMobile={true} />
            ) : (
              content
            )}
          </div>
          <div>
            <Box direction="vertical">
              <Divider />
              <Box
                align="space-between"
                verticalAlign="middle"
                paddingTop="26px"
                paddingLeft="23px"
                paddingRight="23px"
                paddingBottom="26px"
              >
                <Button
                  priority="secondary"
                  skin="dark"
                  dataHook="close-mobile-modal"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t('mobile-brief-cancel')}
                </Button>
                {submitInProgress ? (
                  <Box marginRight="30px">
                    <Loader size="tiny" />
                  </Box>
                ) : (
                  <Button
                    skin="dark"
                    dataHook="submit-mobile-brief"
                    onClick={() => {
                      onSubmitBrief();
                    }}
                  >
                    {t('mobile-brief-submit')}
                  </Button>
                )}
              </Box>
              <Box
                display="inline"
                paddingBottom="30px"
                paddingLeft="22px"
                paddingRight="22px"
              >
                {footer}
              </Box>
            </Box>
          </div>
        </Box>
      )}
    </Modal>
  );
};

export default CompactBriefMobile;
